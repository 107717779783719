*
{
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: 'Inconsolata', monospace;
}
body
{
	background-color: #000;
}

.waitlist{
    background-size: cover;
    background-repeat: no-repeat;
    padding-bottom: 150px;
}

.waitlist-title {
    color: white;
    font-size: 48px;
    text-align: center;
    margin-top: 50px;
}
.waitlist-subtitle {
    color: white;
    font-size: 32px;
    text-align: center;
    margin-top: 24px;
    font-weight: 500;
    padding-bottom: 48px;
	@media screen and (max-width: 768px)
	{
		font-size: 24px;
	}
	@media screen and (max-width: 768px)
	{
		font-size: 18px;
	}
}
.waitlist-form {
    width: 90%;
    max-width: 650px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
	font-size: 24px;
    text-align: center;
}
.waitlist-form input {
    width: calc(100% - 20px);
    padding-left: 20px;
    height: 60px;
    border: none;
    border: 1px solid #999;
    border-radius: 3px;
    font-weight: 500;
    margin-bottom: 20px;
	font-size: 20px;
	width: 100%;
    text-align: center;
}

.waitlist-form select {
    width: 100%;
    padding-left: 20px;
    height: 60px;
    border: none;
    border: 1px solid #999;
    border-radius: 3px;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 20px;
    color: #999;
}

.waitlist-input-half-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.waitlist-modal-referral-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    align-items: center;
}

.waitlist-modal-share-item {
    margin-right: 2.5px;
    margin-left: 2.5px;
}

.waitlist-modal-copy-btn {
    background-color: white;
    color: #061c58;
    font-weight: 800;
    border: 2px solid #061c58;
    border-radius: 3px;
    padding: 10px;
    font-size: 18px;
    cursor: pointer;
    margin-top: 15px;
    margin-left: 15px;
}

.waitlist-input-half {
    width: calc(50% - 10px);
}

.waitlist-input-third {
    width: calc(33% - 10px);
}


.waitlist-input-text {
    color: white;
    margin-bottom: 5px;
    font-weight: 500;
}

.waitlist-form input[type="submit"] {
    background: #061c58;
    color: white;
    box-shadow: none;
    border: 1px solid #061c58;
    height: 70px;
    font-weight: 800;
    padding: 0;
    text-align: center;
}
.waitlist-input-error {
    font-size: 20px;
    transition: .4s all;
    color: red;
}

.waitlist-modal {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.2);
    transition: 0.5s all;
}
.waitlist-modal-inner {
    background-color: rgba(255, 255, 255, 0.95);
    border-radius: 10px;
    margin: 150px auto;
    width: 90%;
    max-width: 800px;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 50px;
}

.waitlist-modal-header {
    font-size: 36px;
    padding-top: 30px;
    padding-bottom: 35px;
}
.waitlist-modal-text {
    font-size: 20px;
    width: 90%;
    margin: 0 auto;
    text-align: center;
}

.waitlist-modal-referral {
    font-size: 40px;
    color: #061c58;
    font-weight: 800;
    width: 90%;
    margin: 0 auto;
    text-align: center;
    margin-top: 20px;
}

.waitlist-modal-btn {
    background: #061c58;
    width: 70%;
    color: white;
    box-shadow: none;
    border: 1px solid #061c58;
    height: 70px;
    font-weight: 800;
    font-size: 20px;
    margin-top: 20px;
}
@media screen and (max-width: 850px) {}

@media screen  and (max-width: 480px)
{
	.waitlist-form
	{
		font-size: 13px;
	}

	.waitlist-form input
	{
		font-size: 15px;
	}
}
